import Image from 'next/image';

const GetApp = () => {
  return (
    <section className="getapp--container">
      <div className="getapp--content">
        <div className="relative w-full h-[290px] sm:h-[400px] md:h-[330px] lg:h-[450px] rounded-none md:rounded-md overflow-hidden">
          <Image src="/images/backgrounds/bg_getapp.webp" alt="bg-getapp" layout="fill" />
        </div>

        <div className="getapp-content--info">
          <div className="getapp-content--title-m">
            <h3 className="font-medium text-white text-[28px] leading-[34px]">
              Download Aplikasi Chakra Sekarang
            </h3>
          </div>

          <div className="getapp-content--main-info">
            <div className="getapp-content--app">
              <div className="label--header">
                <h2 className="hidden md:block text-[28px] text-center md:text-left font-semibold mb-[20px] text-white leading-[40px]">
                  Download Aplikasi Chakra Sekarang
                </h2>
                <div className="label--info">
                  <p className="label--text text-white">
                    Nikmati, Pengalaman Kirim Struk, dan Penukaran Hadiah Dengan Chakra Melalui{' '}
                    Android.
                  </p>
                </div>
              </div>

              <div className="getapp-content--action mt-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.kalbe.cakraloyalty"
                  target="_blank"
                  className="getapp-content--playstore"
                  data-unq="landingpage-button-playstore"
                  rel="noreferrer">
                  <Image
                    src="/images/vendors/playstore.webp"
                    alt="Google Playstore"
                    width={218}
                    height={60}
                  />
                </a>
              </div>
            </div>
            <div className="getapp-content--phone">
              <div className="relative h-full getapp--phone-img">
                <img
                  src="/images/illustrations/getapp-desktop.webp"
                  data-img-src="/images/illustrations/getapp-desktop.webp"
                  loading="lazy"
                  alt="Phone"
                />
              </div>
              <div>
                <div className="relative block md:hidden w-full max-w-[200px] sm:max-w-[215px] h-auto">
                  <img
                    className="w-full h-full object-contain object-center"
                    src="/images/illustrations/getapp-mobile.webp"
                    data-img-src="/images/illustrations/getapp-mobile.webp"
                    alt="Phone"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetApp;
